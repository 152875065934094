import { Link } from 'react-router-dom'
import coverImage from '../../assets/img/cover-image.jpg'
import hotTopic from '../../assets/img/flame.svg'

export const AnnouncementSection = () => {
  return (
    <section className="dark-mode position-relative jarallax pb-xl-3" data-jarallax data-speed="0.4">

        <div className="jarallax-img bg-dark opacity-70" style={{backgroundImage: `url(${coverImage})`}}></div>
        <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-70 zindex-1"></span>
        <div className="container position-relative pb-5 zindex-5">
          <nav className="py-4" aria-label="breadcrumb">
            <ol className="breadcrumb mb-0 py-3">
              <li className="breadcrumb-item">
                <a href="index.html"><i className="bx bx-home-alt fs-lg me-1"></i>Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Blog Homepage</li>
            </ol>
          </nav>

          <div className="row mb-xxl-5 py-md-4 py-lg-5">
            <div className="col-lg-6 col-md-7 pb-3 pb-md-0 mb-4 mb-md-5">
              <div className="mb-3 fs-lg text-light">
                <img src={hotTopic} width="24" alt="Flame icon" className="mt-n1 me-1" />
                Hot Topic
              </div>
              <h1 className="display-5 pb-md-3">Welcome to the FH Love Foundation</h1>
              <div className="d-flex flex-wrap mb-md-5 mb-4 pb-md-2 text-white">
                <div className="border-end border-light h-100 mb-2 pe-3 me-3">
                  <span className="badge bg-faded-light fs-base">Technology</span>
                </div>
                <div className="border-end border-light mb-2 pe-3 me-3 opacity-70">12 hours ago</div>
                <div className="d-flex align-items-center mb-2 text-nowrap">
                  <div className="d-flex align-items-center me-3 opacity-70">
                    <i className="bx bx-like fs-lg me-1"></i>
                    <span className="fs-sm">8</span>
                  </div>
                  <div className="d-flex align-items-center me-3 opacity-70">
                    <i className="bx bx-comment fs-lg me-1"></i>
                    <span className="fs-sm">4</span>
                  </div>
                  <div className="d-flex align-items-center opacity-70">
                    <i className="bx bx-share-alt fs-lg me-1"></i>
                    <span className="fs-sm">2</span>
                  </div>
                </div>
              </div>
              <Link to="/" className="btn btn-lg btn-primary">
                Read More about our Foundation
                <i className="bx bx-right-arrow-alt ms-2 me-n1 lead"></i>
              </Link>
            </div>

            <div className="col-lg-4 offset-lg-2 col-md-5">
              <div className="swiper overflow-hidden w-100 ms-n2 ms-md-0 pe-3 pe-sm-4" 
                style={{maxHeight: '405px'}} 
                data-swiper-options='{
                    "direction": "vertical",
                    "slidesPerView": "auto",
                    "freeMode": true,
                    "scrollbar": {
                    "el": ".swiper-scrollbar"
                    },
                    "mousewheel": true
                }'>
                <div className="swiper-wrapper pe-md-2">
                  <div className="swiper-slide h-auto px-2">

                    <div className="row row-cols-md-1 row-cols-sm-2 row-cols-1 g-md-4 g-3">

                      <div className="col">
                        <article className="card h-100 border-0 shadow-sm card-hover-primary">
                          <div className="card-body pb-0">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <Link to="/" className="badge fs-sm text-nav bg-white text-decoration-none position-relative zindex-2">Business</Link>
                              <span className="fs-sm text-muted">May 24, 2021</span>
                            </div>
                            <h3 className="h5 mb-0">
                              <a href="blog-single.html" className="stretched-link">How the Millennial Lifestyle Changes as Service Prices Rise</a>
                            </h3>
                          </div>
                          <div className="card-footer d-flex align-items-center py-4 text-muted border-top-0">
                            <div className="d-flex align-items-center me-3">
                              <i className="bx bx-like fs-lg me-1"></i>
                              <span className="fs-sm">2</span>
                            </div>
                            <div className="d-flex align-items-center me-3">
                              <i className="bx bx-comment fs-lg me-1"></i>
                              <span className="fs-sm">0</span>
                            </div>
                            <div className="d-flex align-items-center">
                              <i className="bx bx-share-alt fs-lg me-1"></i>
                              <span className="fs-sm">3</span>
                            </div>
                          </div>
                        </article>
                      </div>

                      <div className="col">
                        <article className="card h-100 border-0 shadow-sm card-hover-primary">
                          <div className="card-body pb-0">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <Link to="/" className="badge fs-sm text-nav bg-white text-decoration-none position-relative zindex-2">Digital</Link>
                              <span className="fs-sm text-muted">May 25, 2021</span>
                            </div>
                            <h3 className="h5 mb-0">
                              <a href="blog-single.html" className="stretched-link">Inclusive Marketing: Why and How Does it Work?</a>
                            </h3>
                          </div>
                          <div className="card-footer d-flex align-items-center py-4 text-muted border-top-0">
                            <div className="d-flex align-items-center me-3">
                              <i className="bx bx-like fs-lg me-1"></i>
                              <span className="fs-sm">5</span>
                            </div>
                            <div className="d-flex align-items-center me-3">
                              <i className="bx bx-comment fs-lg me-1"></i>
                              <span className="fs-sm">0</span>
                            </div>
                            <div className="d-flex align-items-center">
                              <i className="bx bx-share-alt fs-lg me-1"></i>
                              <span className="fs-sm">2</span>
                            </div>
                          </div>
                        </article>
                      </div>

                      <div className="col">
                        <article className="card h-100 border-0 shadow-sm card-hover-primary">
                          <div className="card-body pb-0">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <Link to="/" className="badge fs-sm text-nav bg-white text-decoration-none position-relative zindex-2">Technology</Link>
                              <span className="fs-sm text-muted">May 26, 2021</span>
                            </div>
                            <h3 className="h5 mb-0">
                              <a href="blog-single.html" className="stretched-link">A Study on Smartwatch Design Qualities and People's Preferences</a>
                            </h3>
                          </div>
                          <div className="card-footer d-flex align-items-center py-4 text-muted border-top-0">
                            <div className="d-flex align-items-center me-3">
                              <i className="bx bx-like fs-lg me-1"></i>
                              <span className="fs-sm">7</span>
                            </div>
                            <div className="d-flex align-items-center me-3">
                              <i className="bx bx-comment fs-lg me-1"></i>
                              <span className="fs-sm">4</span>
                            </div>
                            <div className="d-flex align-items-center">
                              <i className="bx bx-share-alt fs-lg me-1"></i>
                              <span className="fs-sm">1</span>
                            </div>
                          </div>
                        </article>
                      </div>

                      <div className="col">
                        <article className="card h-100 border-0 shadow-sm card-hover-primary">
                          <div className="card-body pb-0">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <Link to="/" className="badge fs-sm text-nav bg-white text-decoration-none position-relative zindex-2">Startups</Link>
                              <span className="fs-sm text-muted">May 27, 2021</span>
                            </div>
                            <h3 className="h5 mb-0">
                              <a href="blog-single.html" className="stretched-link">Why UX Design Matters and How it Affects Ranking</a>
                            </h3>
                          </div>
                          <div className="card-footer d-flex align-items-center py-4 text-muted border-top-0">
                            <div className="d-flex align-items-center me-3">
                              <i className="bx bx-like fs-lg me-1"></i>
                              <span className="fs-sm">5</span>
                            </div>
                            <div className="d-flex align-items-center me-3">
                              <i className="bx bx-comment fs-lg me-1"></i>
                              <span className="fs-sm">3</span>
                            </div>
                            <div className="d-flex align-items-center">
                              <i className="bx bx-share-alt fs-lg me-1"></i>
                              <span className="fs-sm">9</span>
                            </div>
                          </div>
                        </article>
                      </div>

                      <div className="col">
                        <article className="card h-100 border-0 shadow-sm card-hover-primary">
                          <div className="card-body pb-0">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <Link to="/" className="badge fs-sm text-nav bg-white text-decoration-none position-relative zindex-2">Web</Link>
                              <span className="fs-sm text-muted">May 28, 2021</span>
                            </div>
                            <h3 className="h5 mb-0">
                              <a href="blog-single.html" className="stretched-link">This Week in Search: New Limits and Exciting Features</a>
                            </h3>
                          </div>
                          <div className="card-footer d-flex align-items-center py-4 text-muted border-top-0">
                            <div className="d-flex align-items-center me-3">
                              <i className="bx bx-like fs-lg me-1"></i>
                              <span className="fs-sm">3</span>
                            </div>
                            <div className="d-flex align-items-center me-3">
                              <i className="bx bx-comment fs-lg me-1"></i>
                              <span className="fs-sm">5</span>
                            </div>
                            <div className="d-flex align-items-center">
                              <i className="bx bx-share-alt fs-lg me-1"></i>
                              <span className="fs-sm">2</span>
                            </div>
                          </div>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-scrollbar"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}
