import './App.css';
import {Route, Routes, BrowserRouter} from 'react-router-dom';
import { HomePage } from './pages/Home/HomePage';
import { ComingSoon } from './pages/ComingSoon/ComingSoon';

const App = () => {
  return (
    <BrowserRouter>
      <main className="page-wrapper">
        <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/coming-soon' element={<ComingSoon />} />
        </Routes>
      </main>
    </BrowserRouter>
  );
}

export default App;
