import { Link } from 'react-router-dom'

export const FooterComponent = () => {
  return (
    <footer className="footer dark-mode bg-dark pt-3 pb-3 pb-lg-3">
      <div className="container pt-lg-2 justify-content-lg-between align-items-lg-center">
        <p className="fs-xs text-center text-md-start pb-2 pb-lg-0 mb-0">
          <span className="text-light opacity-50">&copy; 2022 FH Love Foundation - All rights reserved. </span>
        </p>
        <ul id="menu-policy-footer" className="nav sub-nav footer__sub-nav" 
            style={{float: 'right', marginTop:'-25px'}}>
            <li id="menu-item-1333" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1333">
                <div className="form-text fs-sm text-sm-start text-center">
                    <Link to="/" style={{ textDecoration: 'none', marginRight: '5px' }}>Terms</Link> |
                </div>
            </li>
            <li id="menu-item-1335" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1335">
                <div className="form-text fs-sm text-sm-start text-center">
                    <Link to="/" style={{ textDecoration: 'none', marginLeft: '5px' }}>Privacy Policy</Link>.
                </div>
            </li>
        </ul>
      </div>
    </footer>
  )
}
