import { HeaderComponent } from '../../components/Header/HeaderComponent'

export const ComingSoon = () => {
  return (
    <>
      <HeaderComponent />
      <div className="alert alert-primary mb-0" role="alert">
        <h4 className="pt-2 alert-heading">Well done!</h4>
        <p>Aww yeah, you successfully read this important alert message. This example text is going to run a bit longer so that you can see how spacing within an alert works with this kind of content.</p>
        <hr className="opacity-25 my-0" style={{color: 'currentColor'}} />
        <p className="pt-3 mb-2">Whenever you need to, be sure to use margin utilities to keep things nice and tidy.</p>
      </div>
    </>
  )
}
