import { Link } from 'react-router-dom'

export const HeaderComponent = () => {
  return (
    <header className="header navbar navbar-expand-lg navbar-light bg-light navbar-sticky">
        <div className="container px-3">
          <Link to="/" className="navbar-brand pe-3">
            {/* <img src="assets/img/logo.svg" width="47" alt="Silicon" /> */}
            FH Love Foundation 
          </Link>
          {/**Mobile menu */}
          <div id="navbarNav" className="offcanvas offcanvas-end">
            <div className="offcanvas-header border-bottom">
              <h5 className="offcanvas-title">Menu</h5>
              <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                
              </ul>
            </div>
            <div className="offcanvas-footer border-top">
              <Link to="/" className="btn btn-primary w-100">
                <i className="bx bx-cart fs-4 lh-1 me-1"></i>
                &nbsp;Donate now
              </Link>
            </div>      
          </div>
           {/**End Mobile menu */}
          <div className="form-check form-switch mode-switch pe-lg-1 ms-auto me-4" data-bs-toggle="mode">

          </div>
          <button type="button" className="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <Link to="/" className="btn btn-primary btn-sm fs-sm rounded d-none d-lg-inline-flex">
            <i className="bx bx-cart fs-5 lh-1 me-1"></i>
            &nbsp;How to Donate
          </Link>
        </div>
      </header>
  )
}
