import { AnnouncementSection } from '../../components/AnnouncementSection/AnnouncementSection'
import { DonationComponent } from '../../components/DonationComponent/DonationComponent'
import { FooterComponent } from '../../components/Footer/FooterComponent'
import { HeaderComponent } from '../../components/Header/HeaderComponent'

export const HomePage = () => {
  return (
    <div>
        <HeaderComponent />
        <AnnouncementSection />
        <DonationComponent />
        <FooterComponent />
    </div>
  )
}
